<template>
    <div class="section index-section" key="distanceAccusationDistance">
        <div class="section-title">
            <h3>Wieviel Abstand hatten Sie zu dem Fahrzeug vor Ihnen?</h3>
            <p>Bitte prüfen Sie den Bescheid: Wie gering ist der vorgeworfene Abstand</p>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.distanceAccusationDistance" name="radio-4" value="5/10" id="question-8-1">
            <label for="question-8-1" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-ruler.svg"></span><span>5/10 des halben Tachowertes</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.distanceAccusationDistance" name="radio-4" value="4/10" id="question-8-2">
            <label for="question-8-2" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-ruler.svg"></span><span>4/10 des halben Tachowertes</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.distanceAccusationDistance" name="radio-4" value="3/10" id="question-8-3">
            <label for="question-8-3" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-ruler.svg"></span><span>3/10 des halben Tachowertes</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.distanceAccusationDistance" name="radio-4" value=">2/10" id="question-8-4">
            <label for="question-8-4" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-ruler.svg"></span><span>2/10 des halben Tachowertes</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.distanceAccusationDistance" name="radio-4" value=">1/10" id="question-8-5">
            <label for="question-8-5" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-ruler.svg"></span><span>1/10 des halben Tachowertes</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.distanceAccusationDistance" name="radio-4" value="unsicher" id="question-8-6">
            <label for="question-8-6" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-ruler.svg"></span><span>Weiß nicht genau</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'DistanceAccusationDistance',
  computed: mapState({
    user: (state) => state.user
  }),
  methods: {
    next() {
      this.$emit('next');
    }
  },
  created() {
    this.user.distanceAccusationDistance = '';
  }
}
</script>